import React, { useEffect, useRef, useState } from 'react';
import SearchResult from './SearchResult';
import Image from 'next/image';
import { useGeneralContext } from '@/context/generalcontext/GeneralContextProvider';
import { baseUrl } from '@/shared/baseUrl';
import { useRouter } from 'next/router';

function SearchInput() {
    const {
        state,
        setSelectedCategorie,
        setSearch,

        setSelectedsubcategorie,
        setSelectedchildcategorie,
    } = useGeneralContext();
    const { categories, selectedCategorie, selectedSubcategorie, selectedChildcategorie } = state;

    const [showResult, setShowResult] = useState(false);

    const [searchResult, setSearchResult] = useState([]);
    const [tempSearch, setTempSearch] = useState('');

    const openProductResult = () => {
        setShowResult(true);
    };

    const closeProductResult = () => {
        setShowResult(false);
    };

    const searchRef = useRef(null);

    const router = useRouter();
    const navigate = router.push;
    const searchProduct = () => {
        fetch(baseUrl + `/api/product/suggestion/search?searchkey=${tempSearch}`, { method: 'GET' })
            .then(async (res) => {
                const data = await res.json();

                setSearchResult(data.products);
                openProductResult();
            })
            .catch((err) => {
                closeProductResult();
            });
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            closeProductResult();
        }
        if (
            searchRef.current &&
            searchRef.current.contains(event.target) &&
            searchResult.length &&
            tempSearch?.length
        ) {
            openProductResult();
        }
    };

    useEffect(() => {
        if (!tempSearch || !tempSearch.length) {
            closeProductResult();
            return;
        } else {
            searchProduct();
        }
    }, [tempSearch]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, { passive: true });
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setTempSearch('');
        setSearch('');
    }, [selectedSubcategorie, selectedChildcategorie, selectedCategorie]);
    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    setSearch(tempSearch);
                    setShowResult(false);

                    !window.location.pathname.includes('/search') &&
                        !window.location.pathname.includes('/demoreal') &&
                        navigate(`/search`);
                }}
                className="hidden w-full relative z-0 md:flex items-center lg:h-12 h-10 "
            >
                <div className="hidden lg:block bg-lightGray h-full pr-3 rounded-l-md">
                    <select
                        name="categorie"
                        className="h-full  bg-transparent px-3 xl:text-sm text-xs outline-none"
                        value={selectedCategorie}
                        onChange={(event) => {
                            const value = event.target.value;

                            setSelectedCategorie(value);
                        }}
                    >
                        <option value="1">Toutes nos catégories</option>
                        {categories?.map((categorie, index) => {
                            return (
                                <option key={categorie._id} value={categorie._id}>
                                    {categorie?.name}
                                </option>
                            );
                        })}
                    </select>
                </div>

                <div ref={searchRef} className=" h-full  w-full">
                    <input
                        type="text"
                        value={tempSearch}
                        onChange={(e) => {
                            setTempSearch(e.target.value);
                        }}
                        placeholder="Qu’est-ce qui vous ferait plaisir?"
                        className="h-full w-full px-3 text-sm outline-none"
                    />
                    {showResult && <SearchResult products={searchResult} closeShowResult={closeProductResult} />}
                </div>

                <button
                    aria-label="search"
                    type="submit"
                    className="rounded-r-md bg-secondary h-full lg:px-3.5 px-3 flex-shrink-0 flex justify-center items-center outline-none"
                >
                    <div className="h-[25px] w-[25px] relative">
                        <Image fill src="/images/icons/search.svg" alt="search icon" />
                    </div>
                </button>
            </form>
        </>
    );
}

export default SearchInput;
