const dataLayer = [];

export const handleTrackClick = (product) => {
  //Add the product click tracking code here
  if (typeof window == "undefined") return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "productClick",
    ecommerce: {
      click: {
        products: product,
      },
    },
  });
};

export const handleAddToCartAndTrackClick = (
  product,
  variant,
  selectedquantity
) => {
  if (typeof window == "undefined") return;

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "CFA",
      add: {
        products: [
          {
            name: product?.name,
            id: product?._id,
            brand: product?.brand,
            category: product?.cat,
            position: product?.position,
            variant: variant?.name,
            price: variant?.price,
            quantity: selectedquantity,
            NBStock: variant?.NBStock,
            certified: product?.certified,
            warranty: product?.warranty,
          },
        ],
      },
    },
  });
};

export const handleRemoveFromCart = (product) => {
  // Measure the removal of a product from a shopping cart.
  if (typeof window == "undefined") return;

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "removeFromCart",
    ecommerce: {
      remove: {
        products: product,
      },
    },
  });
};

export const handleCheckout = (product) => {
  if (typeof window == "undefined") return;

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        products: product,
      },
    },
  });
};

export const handleLogin = (user) => {
  if (typeof window == "undefined") return;

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({ login: null });
  window.dataLayer.push({
    event: "login",
    login: {
      event: "login",
      authentication_method: "email",
      user_email: user?.email,
    },
  });
};

export const handleSignUp = (user) => {
  if (typeof window == "undefined") return;

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({ signup: null });
  window.dataLayer.push({
    event: "signup",
    signup: {
      event: "signup",
      user_email: user?.email,
      firstname: user?.firstname,
      lastname: user?.lastname,
      phone: user?.phone,
    },
  });
};

export const handlePurshase = (order) => {
  if (typeof window == "undefined") return;

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      purchase: {
        order: order,
      },
    },
  });
};
