import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

function SearchResult({ products, closeShowResult }) {
    const router = useRouter();
    const navigate = router.push;
    return (
        <div
            style={{ zIndex: 100 }}
            className="absolute  overflow-scroll no-scrollbar min-w-[20rem] gap-2 px-2 flex flex-col items-start justify-start top-14 py-4 rounded-md mx-5 bg-slate-200 "
        >
            {products.length ? (
                products.map((product) => {
                    return (
                        <div
                            key={product._id}
                            onClick={() => {
                                closeShowResult();
                                navigate(`/product/${product._id}`);
                            }}
                            className="product  w-full  p-2 rounded-md cursor-pointer gap-2 flex items-center justify-start flex-row px-10"
                        >
                            <div className="w-[40px] h-[40px] relative">
                                <Image fill src={product?.images[0]} alt={product.name} />
                            </div>

                            <p className="leading-tight">{product.name}</p>
                        </div>
                    );
                })
            ) : (
                <div className="w-full text-center">Aucun produit trouvé</div>
            )}
        </div>
    );
}

export default SearchResult;
