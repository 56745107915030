import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

function Tabs({ tabs, selectedTab, setSelectedTab }) {
    const router = useRouter();
    const navigate = router.push;
    return (
        <section className="hide-scrollbar no-scrollbar flex items-end md:gap-x-5 gap-x-6 mb-2 overflow-x-scroll pl-6 md:pr-0 pr-5">
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={`${
                        index === 0 && 'hidden md:flex'
                    } flex-shrink-0 w-max flex items-center gap-3 md:px-7 py-3 cursor-pointer transition-all duration-150 ease-linear rounded-t-xl h-[50px] ${
                        selectedTab.title === tab.title ? 'md:bg-white md:text-black text-white' : 'text-white'
                    }`}
                    onClick={() => {
                        setSelectedTab(tab);
                        if (index === 0) {
                            navigate('/search');
                            if (window.innerWidth < 768) {
                                setIsMenu(true);
                            }
                        } else if (index === 1) {
                            navigate('/liveshopping');
                        } else if (index === 2) {
                            navigate('/demoreal');
                        } else if (index === 3) {
                            navigate('/devis');
                        } else if (index === 4) {
                            navigate('/paysplit');
                        } else {
                            navigate('/search');
                        }
                    }}
                >
                    <div className="relative h-[30px] w-[30px]">
                        <Image priority={true} fill src={`/images/icons/${tab.icon}`} alt="tab icon" />
                    </div>
                    <p className="text-sm font-medium">{tab.title}</p>
                </div>
            ))}
            <div className="flex-shrink-0 bg-secondary text-sm text-white hidden md:flex justify-center items-center flex-col py-2 px-8 ml-auto cursor-pointer">
                <p className="font-semibold">Appelez pour commander</p>
                <div className="flex items-center gap-3 mt-1">
                    <div className="relative h-[25px] w-[25px]">
                        <Image priority={true} fill src={`/images/icons/contact.svg`} alt="contact icon" />
                    </div>
                    <p className="font-bold">33 885 04 94</p>
                </div>
            </div>
        </section>
    );
}

export default Tabs;
