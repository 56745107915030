import * as ActionTypes from './ActionTypes';

// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.

export const Auth = (
    state = {
        isLoading: false,
        isAuthenticated: typeof window !== 'undefined' ? (localStorage?.getItem('token') ? true : false) : false,
        token: typeof window !== 'undefined' ? localStorage?.getItem('token') : null,
        user: typeof window !== 'undefined' ? JSON.parse(localStorage?.getItem('creds')) : null,
        errMess: null,
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,
                isAuthenticated: false,
            };
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: '',
                token: action.token,
                user: action.user,
            };
        case ActionTypes.RECEIVEUSER_SUCCESS:
            return {
                ...state,
                user: action.user,
            };
        case ActionTypes.REFRESH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: '',
                token: action.token,
            };
        case ActionTypes.LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: action.message,
            };
        case ActionTypes.LOGOUT_REQUEST:
            return {
                ...state,
                isLoading: true,
                isAuthenticated: true,
            };
        case ActionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                token: '',
                user: null,
            };

        default:
            return state;
    }
};
