import React from "react";
import Container from "../common/Container";
const logo_bineta_w = "/icons/binetmarketlogo.png";
import logo from "../../../public/icons/binetmarketlogo.png";
import { countries } from "../../plugins/countries";
import Image from "next/image";
const Footer = () => {
  return (
    <footer className="flex px-4  flex-col justify-center bg-primary 2xl:p-12 py-12 xl:pt-5 pt-8 text-white">
      <section className="px-2 flex sm:flex-row flex-col sm:justify-between justify-center items-center gap-y-3 lg:grid grid-cols-12 ">
        <div className="block col-span-3 relative ">
          <Image height={100} width={200} src={logo.src} alt="logo white" />
        </div>
        <div className="block sm:hidden lg:block xl:col-start-4 col-span-6  text-center font-medium text-sm leading-[21px] py-2 ">
          Nous sommes ravis de vous accueillir sur Bawane. Nous sommes également
          heureux de vous livrer en Côte d&apos;Ivoire
          <Image
            width={20}
            height={20}
            className="inline"
            src={countries.find((item) => item.isoCode === "CI").flag}
            alt="Côte d'Ivoire Flag "
          />
          &nbsp; et au Mali et au Mali
          <Image
            width={20}
            height={20}
            className="inline"
            src={countries.find((item) => item.isoCode === "ML").flag}
            alt="Mali Flag "
          />
          via DHL depuis Dakar
          <Image
            width={20}
            height={20}
            className="inline"
            src={countries.find((item) => item.isoCode === "SN").flag}
            alt="Senegal Flag "
          />
        </div>
        <div className="col-span-3 flex items-center xl:justify-center justify-end lg:gap-3 gap-2 ">
          <a
            href="https://www.facebook.com/binetmarket1?locale=fr_FR"
            target="_blank"
          >
            <div className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] relative">
              <Image fill src={"/images/icons/facebook.svg"} alt="facebook" />
            </div>
          </a>
          <a href="https://twitter.com/Binetmarket1" target="_blank">
            <div className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] relative">
              <Image fill src={"/images/icons/twitter.svg"} alt="twitter" />
            </div>
          </a>
          <a
            href={`//api.whatsapp.com/send/?phone=+221777592929&text=Contact`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] relative">
              <Image fill src={"/images/icons/whatsapp.svg"} alt="whatsapp" />
            </div>
          </a>
          <a href="https://www.instagram.com/binetmarket/" target="_blank">
            <div className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] relative">
              <Image fill src={"/images/icons/instagram.svg"} alt="instagram" />
            </div>
          </a>
          <a
            href="https://www.youtube.com/channel/UCH2-GncFuDiU9ZUSq7XgWZA"
            target="_blank"
          >
            <div className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] relative">
              <Image fill src={"/images/icons/youtube.svg"} alt="youtube" />
            </div>
          </a>
          <a href="https://www.tiktok.com/@binetmarket2" target="_blank">
            <div className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] relative">
              <Image fill src={"/images/icons/tiktok.svg"} alt="tiktok" />
            </div>
          </a>
        </div>
      </section>
      <div className="flex mt-10 justify-center items-center w-full">
        <div className="max-w-[350px] rounded bg-secondary text-sm text-white  md:flex justify-center items-center flex-col py-2 px-8 cursor-pointer">
          <p className="font-semibold">Appelez pour commander</p>
          <div className="flex items-center gap-3 mt-1">
            <div className="w-[30px] h-[30px] relative">
              <Image fill src={"/images/icons/contact.svg"} alt="contact" />
            </div>
            <p className="font-bold">33 885 04 94</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
