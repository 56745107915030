import React, { useEffect, useRef, useState } from 'react';
import SearchResult from './SearchResult';
import Image from 'next/image';
import { useGeneralContext } from '@/context/generalcontext/GeneralContextProvider';
import { baseUrl } from '@/shared/baseUrl';
import { useRouter } from 'next/router';

function SearchInputMobile() {
    const {
        state,
        setSelectedCategorie,
        setSearch,

        setSelectedsubcategorie,
        setSelectedchildcategorie,
    } = useGeneralContext();
    const { selectedCategorie, selectedSubcategorie, selectedChildcategorie } = state;

    const [showResult, setShowResult] = useState(false);

    const [searchResult, setSearchResult] = useState([]);
    const [tempSearch, setTempSearch] = useState('');

    const router = useRouter();
    const navigate = router.push;

    const openProductResult = () => {
        setShowResult(true);
    };

    const closeProductResult = () => {
        setShowResult(false);
    };

    const searchRef = useRef(null);

    const searchProduct = () => {
        fetch(baseUrl + `/api/product/suggestion/search?searchkey=${tempSearch}`, { method: 'GET' })
            .then(async (res) => {
                const data = await res.json();

                setSearchResult(data.products);
                openProductResult();
            })
            .catch((err) => {
                closeProductResult();
            });
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            closeProductResult();
        }
        if (
            searchRef.current &&
            searchRef.current.contains(event.target) &&
            searchResult.length &&
            tempSearch?.length
        ) {
            openProductResult();
        }
    };

    useEffect(() => {
        if (!tempSearch || !tempSearch.length) {
            closeProductResult();
            return;
        } else {
            searchProduct();
        }
    }, [tempSearch]);

    useEffect(() => {
        setTempSearch('');
        setSearch('');
    }, [selectedSubcategorie, selectedChildcategorie, selectedCategorie]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, { passive: true });
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    setSearch((search) => tempSearch);
                    setShowResult(false);

                    !window.location.pathname.includes('search') &&
                        !window.location.pathname.includes('demoreal') &&
                        navigate(`/search`);
                }}
                className="md:hidden flex items-stretch mx-6 mt-3 rounded-md "
            >
                <div ref={searchRef} className="relative  w-full ">
                    <input
                        type="text"
                        placeholder="Qu’est-ce qui vous ferait plaisir?"
                        className="h-full w-full p-3 text-xs outline-none"
                        value={tempSearch}
                        onChange={(e) => {
                            setTempSearch(e.target.value);
                        }}
                    />
                    {showResult && <SearchResult products={searchResult} closeShowResult={closeProductResult} />}
                </div>

                <button
                    type="submit"
                    aria-label="search"
                    className="bg-secondary lg:px-3.5 px-3 flex-shrink-0 flex justify-center items-center outline-none"
                >
                    <div className="relative h-[20px] w-[20px]">
                        <Image priority={true} fill src={`/images/icons/search.svg`} alt="search icon" />
                    </div>
                </button>
            </form>
        </>
    );
}

export default SearchInputMobile;
