import * as ActionTypes from './ActionTypes';
import { handleLogin, handlePurshase, handleSignUp } from '../shared/dataLayer';
import toast from 'react-hot-toast';
import { baseUrl } from '@/shared/baseUrl';

// AUTH
export const requestLogin = () => {
    return {
        type: ActionTypes.LOGIN_REQUEST,
    };
};

export const receiveLogin = (response) => {
    return {
        type: ActionTypes.LOGIN_SUCCESS,
        token: response.token,
        user: response.user || response.creds || response.profile,
    };
};

export const receiveToken = (token) => {
    return {
        type: ActionTypes.REFRESH_SUCCESS,
        token: token,
    };
};

export const loginError = (message) => {
    return {
        type: ActionTypes.LOGIN_FAILURE,
        message,
    };
};

export const receiveUser = (user) => {
    return {
        type: ActionTypes.RECEIVEUSER_SUCCESS,
        user: user,
    };
};

export const loginUser = (creds) => (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin());
    return fetch(baseUrl + '/api/auth/signin', {
        // return fetch("http://localhost:5200/api/auth/signin", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(creds),
    })
        .then(
            async (response) => {
                if (response.ok) {
                    console.log(creds);
                    handleLogin(creds);
                    return response;
                } else {
                    const message = await response.json();
                    var error = new Error('Error ' + response.status + ': ' + message?.message);
                    var error = new Error(message?.message);
                    error.response = response;
                    throw error;
                }
            },
            (error) => {
                throw error;
            }
        )
        .then((response) => response.json())
        .then((response) => {
            console.log(response);
            if (response?.token) {
                localStorage.setItem('token', response.token);
                localStorage.setItem('creds', JSON.stringify(response.profile));
                dispatch(receiveLogin(response));
                toast.success('Connexion réussie!');
                return response;
            } else {
                var error = new Error('Error ' + response.status + ': ' + response?.message);
                var error = new Error(response?.message);
                error.response = response;
                throw error;
            }
        })
        .catch((error) => {
            toast.error(`La connexion a échouée: ${error?.message}`);
            dispatch(loginError(error.message));
        });
};
//TO REVIEW!
export const refreshUser = (creds) => (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin());
    return fetch(baseUrl + '/api/user/refresh/profile', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(creds),
    })
        .then(
            (response) => {
                if (response.ok) {
                    return response;
                } else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            (error) => {
                throw error;
            }
        )
        .then((response) => response.json())
        .then(async (response) => {
            if (response.profile) {
                localStorage.setItem('creds', JSON.stringify(response.profile));
                dispatch(receiveUser(response.profile));
            } else {
                var error = new Error('Error ' + response.status);
                error.response = response;
                throw error;
            }
        })
        .catch((error) => dispatch(loginError(error.message)));
};

export const requestLogout = () => {
    return {
        type: ActionTypes.LOGOUT_REQUEST,
    };
};

export const receiveLogout = () => {
    return {
        type: ActionTypes.LOGOUT_SUCCESS,
    };
};
export const logoutUser = () => (dispatch) => {
    dispatch(requestLogout());
    localStorage.removeItem('token');
    localStorage.removeItem('creds');
    dispatch(receiveLogout());
    return true;
};

//Check User

export const checkEmail = (email) => (dispatch) => {
    const bearer = 'Bearer ' + localStorage.getItem('token');
    var req = baseUrl + '/api/auth/checkuser?email=' + email;
    return fetch(req, {
        headers: {
            Authorization: bearer,
        },
    })
        .then(
            (response) => {
                if (response.ok) {
                    return response;
                } else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            (error) => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then((response) => response.json())
        .catch((error) => {
            console.log('email unknown - ok for suscription');
        });
};
export const checkPhone = (phone) => (dispatch) => {
    const bearer = 'Bearer ' + localStorage.getItem('token');
    var req = baseUrl + '/api/auth/checkuser?phone=' + phone;
    return fetch(req, {
        headers: {
            Authorization: bearer,
        },
    })
        .then(
            (response) => {
                if (response.ok) {
                    return response;
                } else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            (error) => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then((response) => response.json())
        .catch((error) => {
            console.log('phone unknown - ok for suscription');
        });
};
//Signup

export const signupUser = (body, indicatif) => (dispatch) => {
    const newUser = {
        email: body.email.value,
        firstname: body.firstname.value,
        lastname: body.lastname.value,
        indicatif: indicatif,
        password: body.password.value,
        phone: body.phone.value,
    };
    console.log(newUser, 'newuser');
    return fetch(baseUrl + '/api/auth/signup', {
        method: 'POST',
        body: JSON.stringify(newUser),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.token) {
                localStorage.setItem('token', response.token);
                localStorage.setItem('creds', JSON.stringify(response.profile));
                handleSignUp(newUser);
                dispatch(receiveLogin(response));
                return response;
            } else {
                var error = new Error('Error ' + response.status);
                error.response = response;
                throw error;
            }
        })
        .catch((error) => {
            console.log('Post user', error);
        });
};

//Update
export const updateProfileUser = (body, indicatif) => (dispatch) => {
    const user = {
        firstname: body.firstname.value,
        lastname: body.lastname.value,
        indicatif: indicatif,
        phone: body.phone.value,
    };
    const bearer = 'Bearer ' + localStorage.getItem('token');
    return fetch(baseUrl + '/api/user/update/profile', {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json',
            Authorization: bearer,
        },
    })
        .then((response) => response.json())
        .then((response) => {
            if (response?.profile) {
                localStorage.setItem('creds', JSON.stringify(response.profile));
                dispatch(receiveUser(response.profile));
                toast.success('Votre compte a été mis à jour!');
                return response;
            } else {
                var error = new Error('Error ' + response.status);
                error.response = response;
                throw error;
            }
        })
        .catch((error) => {
            console.log('Post user', error);
        });
};
export const updatePasswordUser = (password) => (dispatch) => {
    const user = {
        password: password.value,
    };
    console.log(password, user);
    const bearer = 'Bearer ' + localStorage.getItem('token');
    return fetch(baseUrl + '/api/user/update/password', {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json',
            Authorization: bearer,
        },
    })
        .then((response) => response.json())
        .then((response) => {
            if (response?.success) {
                toast.success('Votre mot de passe a été mis à jour!');
                return response;
            } else {
                var error = new Error('Error ' + response.status);
                error.response = response;
                throw error;
            }
        })
        .catch((error) => {
            console.log('Post user', error);
        });
};
//createAddressUser
export const createAddressUser = (form, country, indicatif) => (dispatch) => {
    const bearer = 'Bearer ' + localStorage.getItem('token');
    const formData = new FormData(form);
    const newAddress = {
        name: 'name',
        firstname: formData.get('firstname'),
        lastname: formData.get('lastname'),
        region: formData.get('region'),
        city: formData.get('city'),
        postCode: formData.get('postCode'),
        country: country,
        address: formData.get('address'),
        indicatif: indicatif,
        phone: formData.get('phone'),
    };
    return fetch(baseUrl + '/api/user/address', {
        method: 'POST',
        body: JSON.stringify(newAddress),
        headers: {
            'Content-Type': 'application/json',
            Authorization: bearer,
        },
    })
        .then(
            (response) => {
                if (response.ok) {
                    return response;
                } else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            (error) => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then((response) => response.json())
        .then(async (response) => {
            if (response?.profile) {
                localStorage.setItem('creds', JSON.stringify(response.profile));
                dispatch(receiveUser(response.profile));
                return response;
            }
        })
        .catch((error) => {
            console.log('Error Address Creation');
        });
};

export const createOrderUser = (cartItems, form, country, indicatif, deliveryType, paymentType) => (dispatch) => {
    const bearer = 'Bearer ' + localStorage.getItem('token');

    const formData = new FormData(form);
    const newAddress = {
        name: 'name',
        firstname: formData.get('firstname'),
        lastname: formData.get('lastname'),
        region: formData.get('region'),
        city: formData.get('region'),
        postCode: formData.get('postCode'),
        country: country,
        address: formData.get('address'),
        indicatif: indicatif,
        phone: formData.get('phone'),
    };

    const newOrder = {
        cart: cartItems,
        total: cartItems?.reduce((total, item) => total + parseInt(item?.quantity) * parseInt(item.price), 0),
        address: newAddress,
        deliveryType: deliveryType,
        paymentType: paymentType,
    };
    return fetch(baseUrl + '/api/payment', {
        method: 'POST',
        body: JSON.stringify(newOrder),
        headers: {
            'Content-Type': 'application/json',
            Authorization: bearer,
        },
    })
        .then(
            (response) => {
                if (response.ok) {
                    handlePurshase(newOrder);
                    return response;
                } else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            (error) => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then((response) => response.json())
        .catch((error) => {
            console.log('Error Order Creation');
        });
};

//DHL

export const createRating = (cartItems, form, country, indicatif, deliveryType) => (dispatch) => {
    const formData = new FormData(form);
    const newAddress = {
        name: 'name',
        firstname: formData.get('firstname'),
        lastname: formData.get('lastname'),
        region: formData.get('region'),
        city: formData.get('region'),
        postCode: formData.get('postCode'),
        country: country,
        address: formData.get('address'),
        indicatif: indicatif,
        phone: formData.get('phone'),
    };
    const bearer = 'Bearer ' + localStorage.getItem('token');

    const newOrder = {
        cart: cartItems,
        total: cartItems?.reduce((total, item) => total + parseInt(item?.quantity) * parseInt(item.price), 0),
        address: newAddress,
        deliveryType: deliveryType,
    };
    console.log('X**', newOrder);
    return fetch(baseUrl + '/api/delivery/dhl/rating', {
        method: 'POST',
        body: JSON.stringify(newOrder),
        headers: {
            'Content-Type': 'application/json',
            Authorization: bearer,
        },
    })
        .then(
            (response) => {
                if (response.ok) {
                    return response;
                } else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            (error) => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then((response) => response.json())
        .catch((error) => {
            console.log(JSON.stringify(newOrder));
            console.log('Error Rating DHL Creation');
        });
};

export const sendEmail = (to, subject, text) => {
    const bearer = 'Bearer ' + localStorage.getItem('token');
    console.log('clicked');
    console.log(to, subject, text);
    return fetch(baseUrl + '/api/sendMail/send-email', {
        method: 'POST',
        body: JSON.stringify({ to, subject, text }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: bearer,
        },
    })
        .then(
            (response) => {
                console.log(response);
                if (response.ok) {
                    return response;
                } else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            (error) => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then((response) => response.json())
        .catch((error) => {
            console.log(JSON.stringify({ to, subject, text }));
            console.log('Error sent email');
        });
    // return async (dispatch) => {
    //   console.log("here");
    //   try {
    //     const response = await fetch( "/api/send-email", {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ to, subject, text }),
    //     });
    //     console.log(response);
    //     if (response.ok) {
    //       dispatch({ type: "EMAIL_SENT_SUCCESS" });
    //     } else {
    //       dispatch({ type: "EMAIL_SENT_FAILURE" });
    //     }
    //   } catch (error) {
    //     dispatch({ type: "EMAIL_SENT_FAILURE" });
    //   }
    // };
};
