/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { baseUrl } from '@/shared/baseUrl';
import { useGeneralContext } from '@/context/generalcontext/GeneralContextProvider';
import { IoMdArrowDropdown } from '@react-icons/all-files/io/IoMdArrowDropdown';
import MobileSubcategory from './MobileSubcategory';
function Category({ category, setIsMenu }) {
    const { state, setSelectedCategorie, setSelectedchildcategorie, setSelectedsubcategorie } = useGeneralContext();
    const { selectedCategorie, selectedSubcategorie, selectedChildcategorie } = state;
    const router = useRouter();
    const navigate = router.push;
    const [subcategories, setSubcategories] = useState([]);

    const fetchSubcategories = async () => {
        try {
            const res = await fetch(baseUrl + `/api/category/${category._id}/subwithchild/view`);
            const data = await res.json();
            console.log(data);
            setSubcategories(data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {}, [selectedCategorie, selectedSubcategorie, selectedChildcategorie]);
    const [isSubcategoriesVisible, setIsSubcategoriesVisible] = useState(false);

    useEffect(() => {
        if (isSubcategoriesVisible && !subcategories.length) {
            fetchSubcategories();
        }
    }, [isSubcategoriesVisible]);
    return (
        <div
            key={category._id}
            onMouseOver={() => {
                fetchSubcategories();
            }}
            className={`group w-full  relative md:hover:min-h-[500px]  `}
        >
            <div
                key={category._id}
                className="bg-white flex mx-2 overflow-scroll no-scrollbar  items-center justify-between gap-2 min-w-max cursor-pointer"
            >
                <div className="flex flex-row gap-2 items-center">
                    <div className="hidden md:block relative  h-[25px] w-[25px]">
                        <img className="w-full h-full" src={category.image} alt="tab icon" />
                    </div>
                    <div className="md:hidden h-[40px] w-[40px] rounded-full p-2 bg-lightGray flex items-center justify-center ">
                        <div className="h-[30px] w-[30px] relative   rounded-full ">
                            <img className="w-full h-full" src={category.image} alt="tab icon" />
                        </div>
                    </div>
                    <p
                        className={`text-sm hover:text-secondary ${
                            category._id === selectedCategorie ? 'font-semibold text-secondary' : null
                        }`}
                        onClick={() => {
                            setSelectedCategorie(category._id);

                            if (subcategories?.length > 0) {
                                setIsSubcategoriesVisible(true);
                            } else {
                                setIsMenu && setIsMenu(false);
                            }

                            if (!window.location.pathname.startsWith('/demoreal')) {
                                navigate('/search');
                            }
                        }}
                    >
                        {category?.name}
                    </p>
                </div>

                <p
                    onClick={() => setIsSubcategoriesVisible(!isSubcategoriesVisible)}
                    className={`md:hidden transition-all  ${isSubcategoriesVisible && 'rotate-180'}`}
                >
                    <IoMdArrowDropdown size={20} />
                </p>
                {subcategories.length ? (
                    <div
                        className={`absolute h-fit  ${
                            subcategories.length <= 4
                                ? 'md:min-w-[30vw] lg:min-w-[20vw]'
                                : subcategories.length <= 6
                                ? 'md:min-w-[50vw] lg:min-w-[35vw]'
                                : 'md:min-w-[60vw] lg:min-w-[40vw]'
                        }   hidden gap-8 flex-wrap   bg-white p-6 rounded-md shadow-md   md:group-hover:flex z-50 no-scrollbar top-[50px]`}
                    >
                        {subcategories.map((subcategory, i) => {
                            return (
                                <div className="mt-2" key={i}>
                                    <div
                                        onClick={() => {
                                            setSelectedsubcategorie(subcategory._id);
                                            if (!window.location.pathname.startsWith('/demoreal')) {
                                                navigate('/search');
                                            }
                                        }}
                                        className="text-md text-medium cursor-pointer hover:text-secondary flex items-center  w-full gap-2"
                                    >
                                        <div className=" h-[35px] w-[35px] rounded-full p-2 bg-lightGray flex items-center justify-center ">
                                            <div className="h-[30px] w-[30px] relative   rounded-full ">
                                                <Image
                                                    priority={true}
                                                    objectFit="contain"
                                                    fill
                                                    src={subcategory.image}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div>{subcategory.name}</div>
                                    </div>
                                    <div className="md:flex hidden flex-col gap-2 mt-2">
                                        {subcategory.childcategories?.map((child, i) => (
                                            <div
                                                onClick={() => {
                                                    setSelectedchildcategorie(child._id);
                                                    if (!window.location.pathname.startsWith('/demoreal')) {
                                                        navigate('/search');
                                                    }
                                                }}
                                                className="text-darkGray cursor-pointer hover:text-secondary gap-2  flex flex-row ml-2 items-center"
                                                key={i}
                                            >
                                                <div className="h-[18px] gap-2 w-[18px] relative   rounded-full ">
                                                    <Image
                                                        priority={true}
                                                        objectFit="contain"
                                                        fill
                                                        src={child.image}
                                                        alt=""
                                                    />
                                                </div>

                                                <div>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    ''
                )}
            </div>
            {/* {mobile sub categories} */}
            {subcategories.length && isSubcategoriesVisible ? (
                <div className="flex items-start ml-8 flex-col gap-2 md:hidden">
                    {subcategories.map((subcategory, i) => {
                        return (
                            <MobileSubcategory
                                key={i}
                                subcategory={subcategory}
                                setSelectedchildcategorie={setSelectedchildcategorie}
                                setSelectedsubcategorie={setSelectedsubcategorie}
                                setIsMenu={setIsMenu}
                            />
                        );
                    })}
                </div>
            ) : (
                ''
            )}
        </div>
    );
}

export default Category;
