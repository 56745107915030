import '../styles/globals.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';
import store from '@/redux/store';
import GeneralContextProvider from '@/context/generalcontext/GeneralContextProvider';
import Default from '@/components/layouts/Default';

// import TagManager from 'react-gtm-module';

export default function App({ Component, pageProps }) {
    // useEffect(() => {
    //     const tagManagerArgs = {
    //         gtmId: 'GTM-MSRJDG4J',
    //     };

    //     if (typeof window !== 'undefined') {
    //         TagManager.initialize(tagManagerArgs);
    //     }
    // }, []);
    return (
        <Provider store={store}>
            <Toaster />
            <GeneralContextProvider >
                <Default>
                        <div className="md:mt-10">
                            <Component {...pageProps} />
                        </div>
                   
                </Default>
            </GeneralContextProvider>
        </Provider>
    );
}
