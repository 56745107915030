import React, { createContext, useContext, useEffect, useReducer } from 'react';
import reducer from './reducer';
import ActionTypes from './actiontypes';
import { baseUrl } from '@/shared/baseUrl';

const INITIAL_STATE = {
    categories: [],
    subcategories: [],
    selectedCategorie: '',
    selectedSubcategorie: '',
    selectedChildcategorie: '',
    search: '',
    isCartVisible: false,
    randomData: [],
};

export const GeneralContext = createContext(INITIAL_STATE);

export const useGeneralContext = () => {
    return useContext(GeneralContext);
};
function GeneralContextProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const { categories, subcategories } = state;

    const fetchCategories = async () => {
        try {
            const response = await fetch(baseUrl + '/api/category/view', {
                method: 'POST',
            });
            const data = await response.json();
            dispatch({ type: ActionTypes.FETCH_CATEGORIES, payload: data });
        } catch (error) {
            console.log('Error fetching category data:', error);
        }
    };
    const fetchSubcategories = async () => {
        try {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            const response = await fetch(baseUrl + '/api/subcategory/view', {
                method: 'POST',
                headers: {
                    Authorization: bearer,
                },
            });
            const data = await response.json();
            dispatch({ type: ActionTypes.FETCH_SUBCATEGORIES, payload: data });
        } catch (error) {
            console.error('Failed to fetch subcategories:', error);
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchSubcategories();
    }, []);

    const updateCategoriesWithSubcategories = () => {
        // Create a mapping of category IDs to their respective subcategories
        const categorySubcategoryMap = {};

        subcategories.forEach((subcategory) => {
            const categoryId = subcategory?.category?._id;
            if (!categorySubcategoryMap[categoryId]) {
                categorySubcategoryMap[categoryId] = [];
            }
            categorySubcategoryMap[categoryId].push(subcategory);
        });

        // Update the categories state with subcategories
        const updatedCategories = categories.map((category) => ({
            ...category,
            subcategories: categorySubcategoryMap[category?._id] || [],
        }));

        dispatch({ type: ActionTypes.updateCategoriesWithSubcategories, payload: updatedCategories });
    };

    const setSelectedCategorie = (categorie) => {
        dispatch({ type: ActionTypes.SET_SEARCH, payload: '' });
        dispatch({ type: ActionTypes.SET_CATEGORIE, payload: categorie });
        dispatch({ type: ActionTypes.SET_SUBCATEGORIE, payload: '' });
        dispatch({ type: ActionTypes.SET_CHILDCATEGORIE, payload: '' });
    };

    const setSelectedsubcategorie = (subcategorie) => {
        if (subcategorie) {
            dispatch({ type: ActionTypes.SET_SEARCH, payload: '' });
            dispatch({ type: ActionTypes.SET_CATEGORIE, payload: 1 });
            dispatch({ type: ActionTypes.SET_CHILDCATEGORIE, payload: '' });
        }

        dispatch({ type: ActionTypes.SET_SUBCATEGORIE, payload: subcategorie });
    };

    const setSelectedchildcategorie = (childcategorie) => {
        if (childcategorie) {
            dispatch({ type: ActionTypes.SET_SEARCH, payload: '' });
            dispatch({ type: ActionTypes.SET_CATEGORIE, payload: 1 });
            dispatch({ type: ActionTypes.SET_SUBCATEGORIE, payload: '' });
        }

        dispatch({ type: ActionTypes.SET_CHILDCATEGORIE, payload: childcategorie });
    };

    const setSearch = (search) => {
        dispatch({ type: ActionTypes.SET_SEARCH, payload: search });
        if (search) {
            dispatch({ type: ActionTypes.SET_SUBCATEGORIE, payload: '' });
            dispatch({ type: ActionTypes.SET_CHILDCATEGORIE, payload: '' });
        }
    };

    const setIsCartVisible = (isCartVisible) => {
        dispatch({ type: ActionTypes.SET_CART_VISIBILITY, payload: isCartVisible });
    };
    useEffect(() => {
        updateCategoriesWithSubcategories();
    }, [subcategories]);

    return (
        <GeneralContext.Provider
            value={{
                state,
                setSelectedCategorie,
                setSearch,
                dispatch,
                setIsCartVisible,
                setSelectedsubcategorie,
                setSelectedchildcategorie,
            }}
        >
            {children}
        </GeneralContext.Provider>
    );
}

export default GeneralContextProvider;
