export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const RECEIVEUSER_SUCCESS = "RECEIVEUSER_SUCCESS";

export const EMAIL_SENT_SUCCESS = "EMAIL_SENT_SUCCESS";
export const EMAIL_SENT_FAILURE = "EMAIL_SENT_FAILURE";
