const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
const FETCH_SUBCATEGORIES = 'FETCH_SUBCATEGORIES';
const UPDATE_SUBCATEGORY_WITH_CATEGORIES = 'UPDATE_SUBCATEGORY_WITH_CATEGORIES';
const SET_SEARCH = 'SET_SEARCH';
const SET_CART_VISIBILITY = 'SET_CART_VISIBILITY';
const SET_CATEGORIE = 'SET_CATEGORIE';
const SET_RANDOM = 'SET_RANDOM';
const SET_SUBCATEGORIE = 'SET_SUBCATEGORIE';
const SET_CHILDCATEGORIE = 'SET_CHILDCATEGORIE';

const ActionTypes = {
    FETCH_CATEGORIES,
    FETCH_SUBCATEGORIES,
    UPDATE_SUBCATEGORY_WITH_CATEGORIES,
    SET_SEARCH,
    SET_CART_VISIBILITY,
    SET_CATEGORIE,
    SET_RANDOM,
    SET_SUBCATEGORIE,
    SET_CHILDCATEGORIE,
};
export default ActionTypes;
