/* eslint-disable @next/next/no-img-element */
import React, { useRef, useState, useEffect, createRef } from "react";

import logo_bineta_w from "../../../public/icons/binetmarketlogo.png";

import Image from "next/image";
import { useRouter } from "next/router";
import { baseUrl } from "@/shared/baseUrl";
import dynamic from "next/dynamic.js";
import Link from "next/link.js";
const deleteIcon = "/icons/deleteIcon.svg";

import Tabs from "./Tabs.jsx";
import SearchInput from "./SearchInput.jsx";
import SearchInputMobile from "./SearchInputMobile.jsx";
import { handleRemoveFromCart } from "@/shared/dataLayer.js";
import Category from "./Category.jsx";
const SideMenu = dynamic(() => import("./SideMenu.jsx"));
const CheckoutHeader = dynamic(() => import("./CheckoutHeader.jsx"));

const Header = ({
  auth,
  categories,
  selectedCategorie,
  setSelectedCategorie,

  isCartVisible,
  setIsCartVisible,

  setSelectedsubcategorie,
  setSelectedchildcategorie,
}) => {
  const tabs = [
    {
      title: "Tous nos rayons",
      icon: "tab1.svg",
      slug: "",
      nav: "/search",
      subMenu: categories,
    },
    {
      title: "Live shopping",
      icon: "tab2.svg",
      slug: "",
      nav: "/liveshopping",
      subMenu: categories,
    },
    {
      title: "Démo réelle",
      icon: "tab3.svg",
      slug: "",
      nav: "/demoreal",
      subMenu: categories,
    },
    {
      title: "Obtenir un devis",
      icon: "tab4.svg",
      slug: "",
      nav: "/devis",
      subMenu: categories,
    },
    {
      title: "Paiement par tranches",
      icon: "tab5.svg",
      slug: "",
      nav: "/paysplit",
      subMenu: categories,
    },
  ];

  const router = useRouter();
  const navigate = router.push;

  const [isMenu, setIsMenu] = useState(false);
  const [selectedTab, setSelectedTab] = useState(false);

  const connecterRef = useRef();

  useEffect(() => {
    if (categories) {
      setSelectedTab(tabs[0]);
    }
  }, [categories]);

  const [cartItems, setCartItems] = useState([]);
  useEffect(() => {
    setCartItems(JSON.parse(localStorage.getItem("cartItems")));
    if (isCartVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isCartVisible]);
  const ref = useRef(null);
  const buttonRef = useRef(null);
  const buttonRef2 = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        buttonRef2.current &&
        !buttonRef2.current.contains(event.target) &&
        event.target.tagName !== "BUTTON"
      ) {
        setIsCartVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside, { passive: true });

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleToggleCart = () => {
    setIsCartVisible(!isCartVisible);
  };

  const subcategoriesRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      subcategoriesRef.current &&
      !subcategoriesRef.current.contains(event.target)
    ) {
      // setIsSubcategoriesVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, { passive: true });
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return router?.pathname === "/checkout" ? (
    <CheckoutHeader />
  ) : (
    <div className="md:fixed  z-[99] w-full  no-scrollbar">
      <header>
        <section className="bg-primary  md:pb-0 pb-4">
          <section className="relative flex md:items-center items-center md:justify-start justify-between gap-4 md:gap-7 px-6 z-40 bg-primary pt-7 md:pb-5 pb-2">
            {/* menu icon */}

            <Image
              height={25}
              width={25}
              src={`/images/icons/tab1.svg`}
              alt=""
              priority={true}
              className="md:hidden block flex-shrink-0 filter invert brightness-0 cursor-pointer"
              onClick={() => {
                if (window.innerWidth < 768) setIsMenu(!isMenu);
              }}
            />
            <Link href="/">
              <div className="flex-shrink-0   lg:h-10 lg:mx-4 lg:w-48  md:h-8 h-5 md:w-28  relative">
                <img
                  className="w-full h-full"
                  objectFit="contain"
                  src={logo_bineta_w.src}
                  alt="logo"
                />
              </div>
            </Link>

            {/* search input */}
            <SearchInput />

            {/* profile & cart */}

            <div className="flex items-center xl:gap-5 md:gap-7 gap-2 col-span-4 flex-shrink-0 md:w-max">
              <div ref={connecterRef} className="relative cursor-pointer">
                <div
                  onClick={() =>
                    auth?.isAuthenticated
                      ? navigate(`/account`)
                      : navigate(`/connexion`)
                  }
                  className="flex items-center gap-2 sm:flex-shrink-0 relative"
                >
                  <div className="relative md:flex-shrink-0 w-[20px] h-[20px] xl:mb-1 mb-0 md:w-[30px] md:h-[30px]">
                    <Image
                      priority={true}
                      fill
                      src="/images/icons/profile.svg"
                      alt="profile icon"
                    />
                  </div>
                  {auth?.isAuthenticated && (
                    <p className="absolute text-xs left-5 top-4 md:left-3 md:top-3">
                      🟢
                    </p>
                  )}
                  <p className="hidden xl:block text-white">
                    {auth?.isAuthenticated
                      ? auth?.user?.firstname
                      : "Se connecter"}
                  </p>
                </div>
              </div>
              <div className="relative cursor-pointer">
                <div
                  onClick={async () => {
                    let result;
                    if (window.location.pathname.startsWith("/product")) {
                      const response = await fetch(
                        baseUrl +
                          "/api/product/" +
                          window.location.pathname.split("/").pop()
                      );
                      const data = await response.json();
                      result = "-produit: " + data?.name;
                    } else {
                      result = window.location.pathname;
                    }
                    const isMobileOrTablet =
                      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                      );
                    if (isMobileOrTablet) {
                      window.open(
                        `//api.whatsapp.com/send/?phone=+221777592929&text=Je%20vous%20contacte%20depuis%20la%20section%20${result}.`
                      );
                    } else {
                      window.open(
                        `https://web.whatsapp.com/send/?phone=+221777592929&text=Je%20vous%20contacte%20depuis%20la%20section%20${result}.`
                      );
                    }
                  }}
                  className="flex items-center gap-2 sm:flex-shrink-0"
                >
                  <div className="relative md:flex-shrink-0 w-[20px] h-[20px] md:w-[30px] md:h-[30px] xl:mb-1 mb-0">
                    <Image
                      priority={true}
                      fill
                      src="/images/icons/whatsapp2.svg"
                      alt="whatsapp icon"
                    />
                  </div>
                  <p className="hidden xl:block text-white">Whatsapp</p>
                </div>
              </div>

              <div
                ref={buttonRef}
                className="flex items-center gap-2 sm:flex-shrink-0"
                onClick={() => {
                  handleToggleCart();
                }}
              >
                <div className="relative md:flex-shrink-0 w-[20px] h-[20px] md:w-[30px] md:h-[30px] xl:mb-1 mb-0">
                  <Image
                    priority={true}
                    fill
                    src="/images/icons/cart.svg"
                    alt="cart icon"
                  />
                </div>
                <p className="hidden xl:block text-sm bg-transparent text-white outline-none">
                  Panier
                </p>
              </div>
            </div>
          </section>

          {/* tabs */}
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />

          {/* mobile search */}

          <SearchInputMobile />
        </section>
        <section className="absolute hidden md:flex w-full bg-white h-[65px] left-0 mt-[-12px]"></section>
        {/* submenu */}

        <section className="hide-scrollbar no-scrollbar px-16 py-3 hidden md:flex items-center gap-x-16  overflow-x-scroll overflow-y-visible">
          {selectedTab?.subMenu?.length > 0 && (
            <div className="flex gap-10" ref={subcategoriesRef}>
              {selectedTab.subMenu.map((menu, index) => (
                <Category
                  selectedCategorie={selectedCategorie}
                  setSelectedCategorie={setSelectedCategorie}
                  setSelectedsubcategorie={setSelectedsubcategorie}
                  setSelectedchildcategorie={setSelectedchildcategorie}
                  key={index}
                  category={menu}
                />
              ))}
            </div>
          )}
        </section>

        {isMenu && (
          <SideMenu
            categories={categories}
            tabs={tabs}
            selectedCategorie={selectedCategorie}
            setSelectedCategorie={setSelectedCategorie}
            setSelectedsubcategorie={setSelectedsubcategorie}
            setIsMenu={setIsMenu}
          />
        )}
      </header>
      {isCartVisible && (
        <div
          id="cart-section"
          ref={ref}
          className="fixed flex md:h-screen-md-custom h-screen-custom  md:mt-[210px] flex-col max-w-[400px] items-center justify-center bg-white right-0 bg-black/40 top-0 w-full z-50 p-4 border-l border-gray border-opacity-50"
        >
          <p className="text-secondary flex text-lg font-bold bg-gray-200 py-2 px-4 mb-5 border-b border-gray-200">
            <p>Panier</p>
            <p
              className="cursor-pointer text-red text-xl font-bold ml-2"
              onClick={() => {
                setIsCartVisible(false);
              }}
            >
              &times;
            </p>
          </p>
          {cartItems?.length > 0 ? (
            <div className="h-80vh overflow-y-auto">
              <ul className="space-y-2">
                {cartItems.map((item, index) => (
                  <li key={index} className="flex space-x-2">
                    <div className="relative h-[50px] w-[80px]">
                      <Image
                        objectFit="cover"
                        fill
                        src={item.image}
                        alt="tab icon"
                      />
                    </div>
                    <span className="w-4 h-4 font-bold">{item.quantity}</span>
                    <span className="w-4 font-bold">:</span>
                    <span className="text-sm">
                      {`${item.name} -`}
                      {item.name !== item.variant && (
                        <>
                          <span className="text-secondary">
                            {" "}
                            {item.variant}{" "}
                          </span>
                        </>
                      )}
                      - {new Intl.NumberFormat("fr-FR").format(item.price)} CFA
                    </span>
                    <span
                      ref={buttonRef2}
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        const deletedItems = cartItems.filter((ele) => {
                          return (
                            item?.name === ele?.name &&
                            item?.variant === ele?.variant
                          );
                        });
                        const updatedCartItems = cartItems.filter(
                          (ele) =>
                            !(
                              item?.name === ele?.name &&
                              item?.variant === ele?.variant
                            )
                        );
                        handleRemoveFromCart(deletedItems);
                        localStorage.setItem(
                          "cartItems",
                          JSON.stringify(updatedCartItems)
                        );
                        setCartItems(updatedCartItems);
                      }}
                    >
                      <div className="relative h-[20px] w-[20px]">
                        <Image fill src={deleteIcon} alt="tab icon" />
                      </div>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p className="text-gray-500">Pas de produits dans le panier.</p>
          )}

          <div className="mt-auto w-full flex flex-col items-center justify-center gap-2 py-5 ">
            <p className="text-gray-500">
              {`Sous-Total : ${new Intl.NumberFormat("fr-FR").format(
                cartItems?.length > 0
                  ? cartItems?.reduce(
                      (total, item) =>
                        total + parseInt(item?.quantity) * parseInt(item.price),
                      0
                    )
                  : 0
              )} CFA`}
            </p>
            <button
              className="bg-secondary text-white w-full lg:w-60 h-10 rounded-md hover:bg-opacity-90 hover:bg-yellow hover:text-red"
              onClick={() => {
                setIsCartVisible(false);
                navigate(`/cart`);
              }}
            >
              Voir le panier
            </button>

            <button
              className="bg-yellow text-black w-full lg:w-60 h-10 rounded-md hover:bg-opacity-60 hover:bg-secondary hover:text-red"
              onClick={() => {
                setIsCartVisible(false);
              }}
            >
              Continuer vos achats
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
