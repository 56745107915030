import ActionTypes from './actiontypes';

const generalReducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_CATEGORIES:
            return { ...state, categories: action.payload };
        case ActionTypes.FETCH_SUBCATEGORIES:
            return { ...state, subCategories: action.payload };
        case ActionTypes.UPDATE_SUBCATEGORY_WITH_CATEGORIES:
            return { ...state, categories: action.payload };
        case ActionTypes.SET_SEARCH:
            return { ...state, search: action.payload };
        case ActionTypes.SET_CART_VISIBILITY:
            return { ...state, isCartVisible: action.payload };
        case ActionTypes.SET_CATEGORIE:
            return { ...state, selectedCategorie: action.payload };
        case ActionTypes.SET_SUBCATEGORIE:
            return { ...state, selectedSubcategorie: action.payload };
        case ActionTypes.SET_CHILDCATEGORIE:
            return { ...state, selectedChildcategorie: action.payload };
        case ActionTypes.SET_RANDOM:
            return { ...state, randomData: action.payload };
        default:
            return state;
    }
};
export default generalReducer;
