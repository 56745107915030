import React, { useState } from 'react';
import Image from 'next/image';
import { IoMdArrowDropdown } from '@react-icons/all-files/io/IoMdArrowDropdown';
import { useRouter } from 'next/router';

function MobileSubcategory({ subcategory, setSelectedsubcategorie, setSelectedchildcategorie, setIsMenu }) {
    const [isChildcategoriesVisible, setIsChildcategoriesVisible] = useState(false);
    const router = useRouter();
    const navigate = router.push;

    return (
        <div className="w-full">
            <div className="mt-2 flex items-center  justify-between w-full">
                <div
                    onClick={() => {
                        setSelectedsubcategorie(subcategory._id);
                        if (!window.location.pathname.startsWith('/demoreal')) {
                            navigate('/search');
                        }

                        setIsMenu(false);
                    }}
                    className="text-md flex flex-row items-center justify-center gap-2  text-medium hover:text-secondary cursor-pointer"
                >
                    <div className="md:hidden h-[40px] w-[40px] rounded-full p-2 bg-lightGray flex items-center justify-center ">
                        <div className="h-[30px] w-[30px] relative   rounded-full ">
                            <Image priority={true} fill src={subcategory.image} alt="" />
                        </div>
                    </div>
                    <span>{subcategory.name}</span>
                </div>
                <div
                    onClick={() => setIsChildcategoriesVisible(!isChildcategoriesVisible)}
                    className={`flex h-full  text-center md:hidden  transition-all  ${
                        isChildcategoriesVisible && 'rotate-180'
                    }`}
                >
                    <IoMdArrowDropdown size={20} className="text-gray" />
                </div>
            </div>
            {subcategory.childcategories.length && isChildcategoriesVisible ? (
                <div className="flex items-start ml-8 flex-col gap-2 md:hidden">
                    {subcategory.childcategories.map((childcategory, i) => {
                        return (
                            <div key={i} className="mt-2 flex items-center  justify-between w-full">
                                <div
                                    onClick={() => {
                                        setSelectedchildcategorie(childcategory._id);
                                        if (!window.location.pathname.startsWith('/demoreal')) {
                                            navigate('/search');
                                        }

                                        setIsMenu(false);
                                    }}
                                    className="text-md flex flex-row items-center justify-center gap-2  text-medium hover:text-secondary cursor-pointer"
                                >
                                    <div className="md:hidden h-[40px] w-[40px] rounded-full p-2 bg-lightGray flex items-center justify-center ">
                                        <div className="h-[25px] w-[25px] relative   rounded-full ">
                                            <Image
                                                priority={true}
                                                objectFit="contain"
                                                fill
                                                src={childcategory.image}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <span>{childcategory.name}</span>
                                </div>
                                <div></div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                ''
            )}
        </div>
    );
}

export default MobileSubcategory;
