export const countries = [
  // {
  //   name: "Angola",
  //   dialCode: "+244",
  //   isoCode: "AO",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/ao.svg",
  // },
  // {
  //   name: "Benin",
  //   dialCode: "+229",
  //   isoCode: "BJ",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/bj.svg",
  // },
  // {
  //   name: "Burkina Faso",
  //   dialCode: "+226",
  //   isoCode: "BF",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/bf.svg",
  // },
  // {
  //   name: "Cameroon",
  //   dialCode: "+237",
  //   isoCode: "CM",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/cm.svg",
  // },
  // {
  //   name: "Chad",
  //   dialCode: "+235",
  //   isoCode: "TD",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/td.svg",
  // },
  // {
  // 	name: "Cameroun",
  // 	dialCode: "+237",
  // 	isoCode: "CM",
  // 	flag: "https://cdn.kcak11.com/CountryFlags/countries/cm.svg",
  // },
  {
    name: "Sénégal",
    dialCode: "+221",
    isoCode: "SN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sn.svg",
  },
  {
    name: "Côte d'Ivoire",
    dialCode: "+225",
    isoCode: "CI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ci.svg",
  },
  // {
  //   name: "Central African Republic",
  //   dialCode: "+236",
  //   isoCode: "CF",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/cf.svg",
  // },
  // {
  //   name: "Democratic Republic of Congo",
  //   dialCode: "+243",
  //   isoCode: "CD",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/cd.svg",
  // },
  // {
  //   name: "Equatorial",
  //   dialCode: "+240",
  //   isoCode: "GQ",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/gq.svg",
  // },
  // {
  //   name: "Gabon",
  //   dialCode: "+241",
  //   isoCode: "GA",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/ga.svg",
  // },

  // {
  //   name: "Guinea",
  //   dialCode: "+224",
  //   isoCode: "GN",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/gn.svg",
  // },
  // {
  //   name: "Gambia",
  //   dialCode: "+220",
  //   isoCode: "GM",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/gm.svg",
  // },
  // {
  //   name: "Ghana",
  //   dialCode: "+233",
  //   isoCode: "GH",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/gh.svg",
  // },
  // {
  //   name: "Guinea-Bissau",
  //   dialCode: "+245",
  //   isoCode: "GW",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/gw.svg",
  // },
  // {
  // 	name: "Guinée Conakry",
  // 	dialCode: "+224",
  // 	isoCode: "GN",
  // 	flag: "https://cdn.kcak11.com/CountryFlags/countries/gn.svg",
  // },
  // {
  //   name: "Ivory Coast / Cote d'Ivoire",
  //   dialCode: "+225",
  //   isoCode: "CI",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/ci.svg",
  // },
  // {
  //   name: "Kenya",
  //   dialCode: "+254",
  //   isoCode: "KE",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/ke.svg",
  // },
  // {
  //   name: "Liberia",
  //   dialCode: "+231",
  //   isoCode: "LR",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/lr.svg",
  // },
  {
    name: "Mali",
    dialCode: "+223",
    isoCode: "ML",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ml.svg",
  },
  // {
  //   name: "Mauritania",
  //   dialCode: "+222",
  //   isoCode: "MR",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/mr.svg",
  // },
  // {
  //   name: "Niger",
  //   dialCode: "+227",
  //   isoCode: "NE",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/ne.svg",
  // },
  // {
  //   name: "Nigeria",
  //   dialCode: "+234",
  //   isoCode: "NG",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/ng.svg",
  // },
  // {
  //   name: "Papua New Guinea",
  //   dialCode: "+675",
  //   isoCode: "PG",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/pg.svg",
  // },
  // {
  //   name: "Republic of Congo",
  //   dialCode: "+242",
  //   isoCode: "COG",
  //   flag: "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg",
  // },
  // {
  //   name: "Sao Tome and Principe",
  //   dialCode: "+239",
  //   isoCode: "ST",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/st.svg",
  // },
  // {
  //   name: "Senegal",
  //   dialCode: "+221",
  //   isoCode: "SN",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/sn.svg",
  // },
  // {
  //   name: "Sierra Leone",
  //   dialCode: "+232",
  //   isoCode: "SL",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/sl.svg",
  // },
  // {
  //   name: "Togo Comoros",
  //   dialCode: "+228",
  //   isoCode: "TG",
  //   flag: "https://cdn.kcak11.com/CountryFlags/countries/tg.svg",
  // },
];
