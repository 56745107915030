import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGeneralContext } from '@/context/generalcontext/GeneralContextProvider';
import { logoutUser, receiveToken } from '@/redux/ActionCreators';
import { baseUrl } from '@/shared/baseUrl';
import { toast } from 'react-hot-toast';
import Header from '../shared/Header';
import dynamic from 'next/dynamic';
import Footer from '../shared/Footer';

const Default = ({ children }) => {
    const auth = useSelector((state) => state.auth);
    const router = useRouter();

    const {
        state,
        setSelectedCategorie,
        setSearch,
        setIsCartVisible,
        setSelectedsubcategorie,
        setSelectedchildcategorie,
    } = useGeneralContext();
    const { categories, search, selectedCategorie, isCartVisible } = state;

    const dispatch = useDispatch();

    async function fetchCheckJWTtoken() {
        const bearer = 'Bearer ' + localStorage.getItem('token');

        fetch(baseUrl + '/api/user/refresh/profile', {
            headers: { Authorization: bearer },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res?.token) {
                    localStorage.setItem('token', res.token);
                    localStorage.setItem('creds', JSON.stringify(res?.profile));
                    dispatch(receiveToken(res.token));
                } else {
                    toast.error(`Votre session a expiré. Veuillez vous connecter à votre compte.`);
                    dispatch(logoutUser());
                }
            })
            .catch(() => {
                toast.error(`Votre session a expiré. Veuillez vous connecter à votre compte.`);
                dispatch(logoutUser());
            });
    }

    useEffect(() => {
        if (typeof window !== 'undefined' && localStorage.getItem('token')) fetchCheckJWTtoken();
    }, [router.pathname]);
    return (
        <div className="font-poppins">
            <Header
                auth={auth}
                categories={categories}
                selectedCategorie={selectedCategorie}
                setSelectedCategorie={setSelectedCategorie}
                search={search}
                setSearch={setSearch}
                isCartVisible={isCartVisible}
                setIsCartVisible={setIsCartVisible}
                setSelectedsubcategorie={setSelectedsubcategorie}
                setSelectedchildcategorie={setSelectedchildcategorie}
            />
            <div className={` ${router?.pathname === '/checkout' ? null : 'md:pt-[270px]'}`}>{children}</div>
            <Footer />
        </div>
    );
};

export default Default;
